import React from "react"
import PropTypes from "prop-types"
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"

const Tag = ({ children }) => {
  return (
    <li className="tag">
      <Link
        to={`/tags/${kebabCase(children)}/`}
        className="no-underline"
        style={{ boxShadow: `none` }}
      >
        {children}
      </Link>
    </li>
  )
}

const TagList = ({ tags }) => {
  const listItems = tags.map((tag, index) => <Tag key={index}>{tag}</Tag>)

  return <ul className="list-none flex flex-wrap">{listItems}</ul>
}

Tag.propTypes = {
  children: PropTypes.string.isRequired,
}

TagList.propTypes = {
  tags: PropTypes.array,
}

export default TagList
export { Tag }
