import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import TagList from "./tags"

const BlogPostListItem = ({ post, title }) => {
  return (
    <article key={post.fields.slug}>
      <header>
        <h3 className="post-title">
          <Link style={{ boxShadow: `none` }} to={post.fields.slug}>
            {title}
          </Link>
        </h3>
        <small>{post.frontmatter.date}</small>
      </header>
      <section>
        <p
          className="mt-2 mb-4"
          dangerouslySetInnerHTML={{
            __html: post.frontmatter.description || post.excerpt,
          }}
        />
      </section>
      {post.frontmatter.tags && <TagList tags={post.frontmatter.tags} />}
    </article>
  )
}

const BlogPostList = ({ posts }) => {
  return posts.map(({ node }) => {
    const title = node.frontmatter.title || node.fields.slug
    return <BlogPostListItem post={node} title={title} key={node.fields.slug} />
  })
}

BlogPostListItem.propTypes = {
  post: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    excerpt: PropTypes.string.isRequired,
    frontmatter: PropTypes.shape({
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  title: PropTypes.string.isRequired,
}

BlogPostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      post: PropTypes.shape({
        fields: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
        excerpt: PropTypes.string.isRequired,
        frontmatter: PropTypes.shape({
          date: PropTypes.string.isRequired,
          description: PropTypes.string,
          tags: PropTypes.arrayOf(PropTypes.string),
        }),
      }),
    })
  ),
}

export default BlogPostList
export { BlogPostListItem }
